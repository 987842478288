import './Tooltip.scss';

import type { TooltipProps as MantineTooltipProps } from '@mantine/core';
// eslint-disable-next-line no-restricted-imports
import { Tooltip as MantineTooltip } from '@mantine/core';
import { FunctionComponent, ReactNode } from 'react';

export interface TooltipProps extends Omit<MantineTooltipProps, 'label'> {
  title: string;
  description: ReactNode;
}

export const Tooltip: FunctionComponent<TooltipProps> = ({
  children,
  title,
  description,
  ...props
}) => {
  const content: ReactNode = (
    <div className="tooltip-label-container">
      <p className="tooltip-title">{title}</p>
      {description}
    </div>
  );
  return (
    <MantineTooltip
      {...props}
      label={content}
      position={props.position ?? 'bottom'}
      multiline
      classNames={{
        ...props.classNames,
        tooltip: `tooltip-container shadow-strong ${
          props.classNames?.tooltip ?? ''
        }`,
      }}
    >
      {children}
    </MantineTooltip>
  );
};
