import './TooltipContent.scss';

import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { formatDateFrench } from '../../../../../../services/date/formatDateFrench';

interface ActivitiesTooltipContentProps {
  date: string;
  activityNames: string[];
}

export const ActivitiesToolTipContent: FunctionComponent<
  ActivitiesTooltipContentProps
> = ({ date, activityNames }) => {
  const { t } = useTranslation();

  const distinctActivityNames = Array.from(new Set(activityNames));

  return (
    <div className="tooltip-content-container">
      <p className="tooltip-title">
        {t('followup.activities.toolTipDateSection')}
      </p>
      <p className="tooltip-text">{formatDateFrench(date)}</p>

      <>
        <p className="tooltip-title">
          {t('followup.activities.toolTipActivitiesSection')}
        </p>

        {distinctActivityNames.map((activityName) => {
          return (
            <p className="tooltip-text-nowrap" key={`${activityName}`}>
              {`${activityName}`}
            </p>
          );
        })}
      </>
    </div>
  );
};
